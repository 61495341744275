import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { kebabCase } from '../utils/string';
import { TopHeading } from '../components/heading';

class TagsPageTemplate extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const langKey = this.props.pageContext.langKey;
        let tags = data.allMarkdownRemark.group;
        tags = tags.filter(tag => {
            for (const node of tag.nodes) {
                if (node.fields.langKey === langKey) {
                    return true;
                }
            }
            return false;
        });

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang={langKey}
                hasGerman={true}
                slug={this.props.path}
            >
                <SEO
                    lang={langKey}
                    title="Tags"
                    description={data.site.siteMetadata.description}
                />
                <TopHeading>
                    {langKey === 'de' ? 'Alle Tags' : 'All tags'}
                </TopHeading>
                <ul style={{ marginLeft: '20px' }}>
                    {tags.map(({ fieldValue, nodes }) => {
                        const count = nodes.reduce(
                            (prev, curr) =>
                                prev +
                                (curr.fields.langKey === langKey ? 1 : 0),
                            0
                        );
                        const langCode = langKey === 'en' ? `` : `/${langKey}`;
                        const linkTo = `${langCode}/tags/${kebabCase(
                            fieldValue
                        )}/`;

                        return (
                            <li key={fieldValue}>
                                <h3
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <Link
                                        style={{ boxShadow: `none` }}
                                        to={linkTo}
                                    >
                                        {fieldValue} ({count})
                                    </Link>
                                </h3>
                            </li>
                        );
                    })}
                </ul>
            </Layout>
        );
    }
}

export default TagsPageTemplate;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(
            limit: 2000
        ) {
            group(field: frontmatter___tags) {
                fieldValue
                nodes {
                    fields {
                        langKey
                    }
                }
            }
        }
    }
`;
